import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Paper,
  Grid,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import { styled } from "@mui/material/styles";
import GoogleIcon from "@mui/icons-material/Google";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import BackgroundImage1 from "../../Assets/BG_1.png";
import { toast } from "react-toastify";

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  color: "#c76e00",
  backgroundColor: "#fff",
  border: "2px solid #c76e00",
  borderRadius: "10px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#f6f6f6",
    border: "1px solid #dadce0",
  },
}));

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(0.5),
  color: "black",
  backgroundColor: "#e0e0e0",
  borderRadius: "10px",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
}));

const LoginTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#c76e00",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#c76e00",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#c76e00",
    },
  },
});

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [googleUser, setGoogleUser] = useState(null);
  const [googleProfile, setGoogleProfile] = useState(null);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    const rememberedPassword = localStorage.getItem("rememberedPassword");
    if (rememberedEmail && rememberedPassword) {
      setEmail(rememberedEmail);
      setPassword(rememberedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoader(true);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/login`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("userEmail", email);

        if (rememberMe) {
          localStorage.setItem("rememberedEmail", email);
          localStorage.setItem("rememberedPassword", password);
        } else {
          localStorage.removeItem("rememberedEmail");
          localStorage.removeItem("rememberedPassword");
        }

        if (response.data.role === "User") {
          window.location.href = "/userDashboard";
        } else if (response.data.role === "Drafter") {
          window.location.href = "/drafterDashboard";
        }
      } else {
        toast.error("Incorrect Login");
      }
    } catch (error) {
      toast.error(error.response.data);
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setGoogleUser(codeResponse);
      localStorage.setItem("token", codeResponse.access_token);

      if (codeResponse.access_token) {
        try {
          const res = await axios.get(
            `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
            {
              headers: {
                Authorization: `Bearer ${codeResponse.access_token}`,
                Accept: "application/json",
              },
            }
          );

          setGoogleProfile(res.data);
        
          localStorage.setItem("userEmail", res.data.email);
          localStorage.setItem("userName", res.data.name);

          const formData = new FormData();
          formData.append("name", res.data.name);
          formData.append("email", res.data.email);

          const response = await axios.post(
            `${process.env.REACT_APP_SERVER}/googleSignup`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.status === 200) {
            localStorage.setItem("userId", response.data.userId);
            window.location.href = "/userDashboard";
          } else {
            console.error(response.status);
            toast.error("Please try again");
          }
        } catch (error) {
          toast.error(error.response?.data || "An error occurred");
          console.error(error);
        }
      } else {
        toast.error("Login Failed");
      }
    },
    onError: (error) => console.error("Google Login Failed:", error),
  });

  useEffect(() => {
    if (googleUser) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setGoogleProfile(res.data);
          localStorage.setItem("userProfile", JSON.stringify(res.data));
          localStorage.setItem("userId", res.data.id);
          localStorage.setItem("userEmail", res.data.email);
        })
        .catch((err) => console.error(err));
    }
  }, [googleUser]);

  return (
    <Grid container style={{ height: "100vh" }}>
      {matches && (
        <Grid
          item
          md={5}
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ textAlign: "center", height: "100%" }}>
            <img
              src={BackgroundImage1}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={7}
        component="main"
        style={{
          backgroundColor: "#f7f7f7",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container maxWidth="xs">
          <Paper
            elevation={0}
            sx={{
              mt: 8,
              display: "flex",
              flexDirection: "column",
              p: 2,
            }}
            style={{ padding: "5% 10%" }}
          >
            <b style={{ textAlign: "left", fontSize: "20px" }}>Sign In</b>
            <p style={{ fontSize: "16px", marginTop: "2%" }}>
              Welcome back! Please enter your details.
            </p>
            <StyledButton
              startIcon={<GoogleIcon />}
              fullWidth
              onClick={() => {
                googleLogin();
              }}
            >
              User Sign in with Google
            </StyledButton>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <LoginTextField
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: "#c76e00" }}
                    >
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <LoginTextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ color: "#c76e00" }}
                    >
                      <KeyIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        style={{ color: "#c76e00" }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                      name="remember"
                      sx={{
                        color: "#c76e00",
                        "&.Mui-checked": {
                          color: "#c76e00",
                        },
                      }}
                    />
                  }
                  label="Remember me"
                />
                <a
                  href="/forgotPassword"
                  style={{ color: "#c76e00", textDecoration: "none" }}
                >
                  Forgot Password?
                </a>
              </div>
              <StyledSubmitButton
                fullWidth
                style={{ marginTop: "10%" }}
                onClick={handleSubmit}
              >
                <b>Login</b>
              </StyledSubmitButton>
              <center>
                {loader && (
                  <p style={{ color: "red" }}>Creating. Please Wait!</p>
                )}
                <Box
                  sx={{ justifyContent: "space-between", mt: 3 }}
                  style={{ marginTop: "3%" }}
                >
                  Not Registered Yet?
                  <Link
                    href="/signup"
                    style={{ color: "#c76e00", textDecoration: "none" }}
                  >
                    <b> {"Create an Account"}</b>
                  </Link>
                </Box>
              </center>
            </Box>
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Login;
