import { styled } from "@mui/material";

export const BlinkingCircle = styled("div")(({ radius }) => ({
  width: `${radius}px`,
  height: `${radius}px`,
  backgroundColor: "orange",
  borderRadius: "50%",
  animation: "blink 1s infinite",
  "@keyframes blink": {
    "0%": { opacity: 1 },
    "50%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}));
