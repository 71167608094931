import axios from "axios";
import { toast } from "react-toastify";

const getUserInfo = () => {
  const email = localStorage.getItem("userEmail");
  return {
    email,
  };
};

const changeState = async (s3, partId, state) => {
  try {
    const paramsGet = {
      Bucket: "iad-interface",
      Key: `Form_Data/${partId}.json`,
    };

    const data = await s3.getObject(paramsGet).promise();
    const parsedData = JSON.parse(data.Body.toString());

    const paramsPut = {
      Bucket: "iad-interface",
      Key: `Form_Data/${partId}.json`,
      Body: JSON.stringify({ ...parsedData, state }),
      ContentType: "application/json",
    };

    await s3.putObject(paramsPut).promise();
  } catch (error) {
    console.error("Failed to Upload State", error);
  }
};

const EMAIL_TYPE = Object.freeze({
  COMPLETION_EMAIL: "sendCompletionEmail",
  FEEDBACK_ADDED_EMAIL: "sendFeedbackAddedEmail",
  FEEDBACK_RESOLVED_EMAIL: "sendFeedbackResolvedEmail",
});

const sendEmail = async (payload, emailType) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/${emailType}`,
      {
        ...payload,
      }
    );
    if (response.status === 200) {
      toast.info("Email Sent Successfully");
    }
  } catch (error) {
    toast.error("Email Sent Failed");
  }
};

const fetchAllComments = async (s3, partId) => {
  try {
    const paramsGet = {
      Bucket: "iad-interface",
      Key: `Comments/${partId}.json`,
    };

    const data = await s3.getObject(paramsGet).promise();
    const commentsArray = JSON.parse(data.Body.toString());

    return commentsArray;
  } catch (error) {
    return [];
  }
};

export { getUserInfo, changeState, sendEmail, EMAIL_TYPE, fetchAllComments };
