import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Pagination,
  CircularProgress,
} from "@mui/material";
import { hslToRgb, Stack, styled } from "@mui/system";
import SideNavBar from "../../Commons/Sidebar";
import AWS from "aws-sdk";
import { CloudDownload, CloudUpload } from "@mui/icons-material";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import axios from "axios";
import { BlinkingCircle } from "./BlinkingCircle";
import { EMAIL_TYPE, sendEmail } from "../../utils/helper";
import CommentsDialog from "./CommentsDialog";

const StyledButton = styled(Button)({
  border: "1px solid #c76e00",
  backgroundColor: "white",
  color: "#c76e00",
  "&:hover": {
    backgroundColor: "white",
  },
});

const DrafterDashboard = () => {
  const [userFiles, setUserFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [Isfetching, setIsFetching] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [partID, setPartID] = useState("");

  const itemsPerPage = 10;
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    } else {
      console.error("File input ref is not assigned");
    }
  };

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3();

  useEffect(() => {
    const fetchUserFiles = async () => {
      try {
        const params = {
          Bucket: "iad-interface",
          Prefix: "Form_Data/",
        };

        const data = await s3.listObjectsV2(params).promise();
        const jsonFiles = data.Contents.filter((file) =>
          file.Key.endsWith(".json")
        );

        jsonFiles.sort(
          (a, b) => new Date(b.LastModified) - new Date(a.LastModified)
        );

        const filePromises = jsonFiles.map((file) =>
          s3
            .getObject({ Bucket: "iad-interface", Key: file.Key })
            .promise()
            .then((res) => JSON.parse(res.Body.toString()))
        );

        const filesData = await Promise.all(filePromises);
        const filteredFiles = filesData.filter(
          (file) => !file.isDone || file.state === "CHANGES_REQUESTED"
        );

        // Calculate upload count for each user
        const userUploadCounts = {};
        const filesWithUploadCount = filteredFiles.map((file) => {
          const userEmail = file.userEmail;
          if (!userUploadCounts[userEmail]) {
            userUploadCounts[userEmail] = 0;
          }
          userUploadCounts[userEmail] += 1;
          return { ...file, uploadCount: userUploadCounts[userEmail] };
        });

        filesWithUploadCount.sort((a, b) => {
          if (
            a.state === "CHANGES_REQUESTED" &&
            b.state !== "CHANGES_REQUESTED"
          ) {
            return -1;
          }
          if (
            a.state !== "CHANGES_REQUESTED" &&
            b.state === "CHANGES_REQUESTED"
          ) {
            return 1;
          }
          return 0;
        });

        setUserFiles(filesWithUploadCount);
        setIsFetching(false);
      } catch (error) {
        console.error("Error fetching user files:", error);
      }
    };

    fetchUserFiles();
  }, []);

  const uploadJsonToS3 = async (data, key) => {
    const params = {
      Bucket: "iad-interface",
      Key: key,
      Body: JSON.stringify(data),
      ContentType: "application/json",
    };

    try {
      const r = await s3.upload(params).promise();
    } catch (error) {
      console.error("Error uploading JSON to S3:", error);
    }
  };

  const handleDownloadStep = async (stepFile) => {
    if (stepFile) {
      try {
        const key = stepFile.split(
          `${process.env.REACT_APP_S3_BUCKET_NAME}/`
        )[1];
        if (stepFile.includes("https")) {
          const urlString = decodeURIComponent(stepFile);
          key = urlString.split(`amazonaws.com/`)[1];
        }

        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Key: key,
        };

        const data = await s3.getObject(params).promise();
        const blob = new Blob([data.Body], { type: data.ContentType });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = key.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the STEP file:", error);
        alert("Error downloading the STEP file");
      }
    } else {
      alert("STEP file isn't available yet");
    }
  };

  const handleDownload = async (outputFile) => {
    if (outputFile) {
      try {
        // const url = new URL(outputFile.replace("s3://", "https://"));
        // const bucketName = url.hostname;
        // const key = url.pathname.substring(1);

        let key = outputFile.split(
          `${process.env.REACT_APP_S3_BUCKET_NAME}/`
        )[1];
        if (outputFile.includes("https")) {
          const urlString = decodeURIComponent(outputFile);
          key = urlString.split(`amazonaws.com/`)[1];
        }

        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Key: key,
        };

        const data = await s3.getObject(params).promise();
        const blob = new Blob([data.Body], { type: data.ContentType });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = key.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
        toast.error("Error downloading the file");
      }
    } else {
      toast.error("Output file isn't available yet");
    }
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    toast.info("File Selected");
  };

  const handleUpload = async (file, key) => {
    if (!selectedFile) {
      toast.warn(
        "Please select a file to upload. Upload the same file if the generated output is alright"
      );
      return;
    }

    const Part_Id = file.modelFile
      ? file.modelFile.split("/").pop().split("_")[0]
      : "Unknown";

    const uploadParams = {
      Bucket: "iad-interface",
      Key: `Output_Files/Drafter_Output/${Part_Id}_${selectedFile.name}`,
      Body: selectedFile,
      ContentType: "image/svg+xml",
    };

    try {
      const uploadResult = await s3.upload(uploadParams).promise();
      file.Output_File = uploadResult.Location;
      const updatedKey = `Form_Data/${Part_Id}.json`;
      await uploadJsonToS3(file, updatedKey);
      toast.info("File Uploaded");
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error Uploading File");
    }
  };

  const handleSubmit = async (file) => {
    try {
      await handleUpload(file, `Form_Data/${file.Part_ID}.json`);

      const prevState = file.state;

      file.isDone = true;
      file.state = "FEEDBACK_RESOLVED";

      const Part_Id = file.modelFile
        ? file.modelFile.split("/").pop().split("_")[0]
        : "Unknown";

      const updatedKey = `Form_Data/${Part_Id}.json`;

      await uploadJsonToS3(file, updatedKey);

      var name = localStorage.getItem("userName");
      if (prevState == "CHANGES_REQUESTED") {
        await sendEmail(
          { userID: file.userId, file_name: file.modelFileName },
          EMAIL_TYPE.FEEDBACK_RESOLVED_EMAIL
        );
      }

      // Not sure if the email below will be sent from UI so commenting it out for now
      // await sendEmail({ email: file.userEmail }, EMAIL_TYPE.COMPLETION_EMAIL);

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Failed to fetch SVG file:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Internal/External tester filtering logic
  const INTERNAL_TESTERS_LIST = process.env.REACT_APP_INTERNAL_TESTERS_LIST;

  let externalTesterList = [];
  let internalTesterList = [];
  for (let index = 0; index < userFiles.length; index++) {
    if (INTERNAL_TESTERS_LIST.includes(userFiles[index].userEmail)) {
      internalTesterList.push(userFiles[index]);
    } else {
      externalTesterList.push(userFiles[index]);
    }
  }
  const testersSortedList = [...externalTesterList, ...internalTesterList];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = testersSortedList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleDialogOpen = (part) => {
    setPartID(part);
    setOpen(true);
  };

  return (
    <>
      <SideNavBar />
      <CommentsDialog open={open} setOpen={setOpen} partID={partID} />
      <Container
        maxWidth="md"
        style={{ marginTop: "3%", marginBottom: "4%", marginLeft: "28%" }}
      >
        <Grid container spacing={2}>
          {Isfetching ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
              }}
            >
              <CircularProgress
                color="secondary"
                sx={{
                  color: "#c76e00",
                }}
              />
            </div>
          ) : (
            currentItems.map((file, index) => (
              <Grid item xs={12} key={index} style={{ margin: "2% 0" }}>
                <Card
                  style={{
                    padding: "1%",
                    borderRight: "10px solid #c76e00",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>
                          User: {file.userEmail}
                        </Typography>
                        {file.state === "CHANGES_REQUESTED" && (
                          <BlinkingCircle radius={20} />
                        )}
                      </Stack>

                      {file.state === "CHANGES_REQUESTED" && (
                        <Button
                          sx={{
                            fontWeight: "bold",
                            color: "#c76e00",
                            borderColor: "#c76e00",
                            "&:hover": {
                              borderColor: "#c76e00",
                              boxShadow: "none",
                            },
                          }}
                          variant="outlined"
                          onClick={() =>
                            handleDialogOpen(
                              file.modelFile &&
                                file.modelFile.split("/").pop().split("_")[0]
                            )
                          }
                        >
                          View Comments
                        </Button>
                      )}
                    </div>

                    <Typography variant="body2">
                      <ul>
                        <li>
                          <b>Upload Count:</b> {file.uploadCount}
                        </li>
                      </ul>
                    </Typography>
                    <Typography variant="body2">
                      <ul>
                        <li>
                          <b>Part ID:</b>{" "}
                          {file.modelFile
                            ? file.modelFile.split("/").pop().split("_")[0]
                            : "Unknown"}
                        </li>
                      </ul>
                    </Typography>
                    <Typography variant="body2">
                      <ul>
                        <li>
                          <b>Part Name:</b>{" "}
                          {file.modelFileName ? file.modelFileName : "Unknown"}
                        </li>
                      </ul>
                    </Typography>
                    <Typography variant="body2">
                      <ul>
                        <li>
                          <b>Design Intent:</b> {file.designIntent}
                        </li>
                      </ul>
                    </Typography>
                    <br />
                    <center>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          width: "100%",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={() => handleDownload(file.Output_File)}
                          style={{
                            border: "1px solid #c76e00",
                            borderRadius: "20px",
                            width: "30%",
                            backgroundColor: "white",
                            color: "black",
                            margin: "1%",
                            fontSize: "10px",
                            textTransform: "capitalize",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CloudDownload style={{ marginRight: "8px" }} />
                          <span>Download Draft 2D Drawing</span>
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => handleDownloadStep(file.modelFile)}
                          style={{
                            border: "1px solid #c76e00",
                            borderRadius: "20px",
                            width: "30%",
                            backgroundColor: "white",
                            color: "black",
                            margin: "1%",
                            fontSize: "10px",
                            textTransform: "capitalize",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CloudDownload style={{ marginRight: "8px" }} />
                          <span>Download STEP File</span>
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={handleUploadClick}
                          style={{
                            border: "1px solid #c76e00",
                            borderRadius: "20px",
                            width: "30%",
                            backgroundColor: "white",
                            color: "black",
                            margin: "1%",
                            fontSize: "10px",
                            textTransform: "capitalize",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CloudUpload style={{ marginRight: "8px" }} />
                          <span>Upload Updated 2D Drawing</span>
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => handleSubmit(file)}
                          style={{
                            border: "1px solid #c76e00",
                            borderRadius: "20px",
                            width: "30%",
                            backgroundColor: "white",
                            color: "black",
                            margin: "1%",
                            fontSize: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </center>
                  </CardContent>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
        <Pagination
          count={Math.ceil(userFiles.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Container>
      <input
        type="file"
        accept=".svg"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
    </>
  );
};

export default DrafterDashboard;
