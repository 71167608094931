import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import * as moment from "moment";

function CommentCard({ comment }) {
  return (
    <Card style={{ margin: 2 }}>
      <CardContent>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: "bold" }}
        >
          Feedback
        </Typography>
        <Typography variant="body1">{comment.comment}</Typography>
        <div style={{ marginTop: 20 }}>
          <Divider />
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1}>
              <Typography gutterBottom variant="body2">
                Zone :
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                style={{ fontWeight: "bold" }}
              >
                {comment.zone ? comment.zone.toUpperCase() : "NA"}
              </Typography>
            </Stack>
            <Typography gutterBottom variant="body2">
              {moment(comment.timestamp).format("MMMM Do YYYY, h:mm:ss a")}
            </Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default CommentCard;
