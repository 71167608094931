import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Rating,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import AWS from "aws-sdk";
import axios from "axios";
import { CloudDownload, Save } from "@mui/icons-material";
import {
  changeState,
  EMAIL_TYPE,
  fetchAllComments,
  getUserInfo,
  sendEmail,
} from "../../utils/helper";
import posthog from "posthog-js";
import { toast } from "react-toastify";
import CommentCard from "./CommentCard";

const Viewer = () => {
  const { partID } = useParams();
  const [partId] = useState(partID);
  const [outputFileLocalURL, setOutputFileLocalURL] = useState("");
  const [outputFileS3HTTPURL, setOutputFileS3HTTPURL] = useState("");
  const [svgUrl, setSvgUrl] = useState("");
  const [comment, setComment] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [dxfId, setDxfId] = useState(null);
  const [dxfMinutes, setDxfMinutes] = useState(null);
  const [dxfButtonLabel, setDxfButtonLabel] = useState("Download DXF");
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [zone, setZone] = useState("");
  const [zoneNumber, setZoneNumber] = useState("");

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3();

  const getOutputFile = async (outputFileURL) => {
    if (outputFileURL) {
      try {
        var key = outputFileURL.split(
          `${process.env.REACT_APP_S3_BUCKET_NAME}/`
        )[1];
        if (outputFileURL.includes("https")) {
          const urlString = decodeURIComponent(outputFileURL);
          key = urlString.split(`amazonaws.com/`)[1];
        }

        const params = {
          Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
          Key: key,
        };

        const data = await s3.getObject(params).promise();
        const blob = new Blob([data.Body], { type: data.ContentType });
        const finalImageURL = window.URL.createObjectURL(blob);
        return finalImageURL;
      } catch (error) {
        console.error("Error downloading the file form S3", error);
        toast.error(
          "Error downloading the file from cloud, please contact support"
        );
      }
    } else {
      toast.warn("Output file isn't available yet");
    }
  };

  const fetchSVGData = async (partId, refresh = true) => {
    try {
      const params = {
        Bucket: "iad-interface",
        Key: `Form_Data/${partId}.json`,
      };

      const data = await s3.getObject(params).promise();
      const fileData = JSON.parse(data.Body.toString());

      const localURL = await getOutputFile(fileData.Output_File);
      if (refresh) {
        setOutputFileLocalURL(localURL);
        setOutputFileS3HTTPURL(fileData.Output_File);
      }
      return fileData;
    } catch (error) {
      console.error("Error fetching SVG data:", error);
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        await fetchSVGData(partId);
        const fetchedComments = await fetchAllComments(s3, partId);
        const sortedComments = fetchedComments.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        setComments(sortedComments.slice(0, 10));
      } catch (error) {
        console.error("Error fetching comments:", error);
      } finally {
        setLoading(false);
      }
    };

    if (partId) {
      getData(); // Fetch comments when partId is available
    }
  }, []);

  useEffect(() => {
    setSvgUrl(outputFileLocalURL);
  }, [outputFileLocalURL]);

  useEffect(() => {
    setOpenSnackbar(true);
  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleCommentSave = async () => {
    const user = getUserInfo();
    posthog.capture("comment_added", {
      user,
    });

    try {
      // Fetch the existing comments array from S3
      const paramsGet = {
        Bucket: "iad-interface",
        Key: `Comments/${partId}.json`,
      };

      let existingComments = [];

      try {
        const data = await s3.getObject(paramsGet).promise();
        existingComments = JSON.parse(data.Body.toString());

        if (!Array.isArray(existingComments)) {
          existingComments = [];
        }
      } catch (error) {
        if (error.code === "NoSuchKey") {
          existingComments = [];
        } else {
          throw error;
        }
      }

      if (!comment) {
        toast.warning("Please enter a comment");
        return;
      }

      const selectedZone = zone + zoneNumber;
      const newComment = {
        comment,
        rating,
        user,
        zone: selectedZone,
        timestamp: new Date().toISOString(),
      };
      existingComments.push(newComment);

      const paramsPut = {
        Bucket: "iad-interface",
        Key: `Comments/${partId}.json`,
        Body: JSON.stringify(existingComments),
        ContentType: "application/json",
      };

      await s3.putObject(paramsPut).promise();
      await changeState(s3, partId, "CHANGES_REQUESTED");

      const partInfo = await fetchSVGData(partId, false);
      await sendEmail(
        { user_email: partInfo.userEmail, file_name: partInfo.modelFileName },
        EMAIL_TYPE.FEEDBACK_ADDED_EMAIL
      );

      setComment("");
      setZone("");
      setZoneNumber("");

      toast.info("Comment Saved!");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error saving comment to S3:", error);
    }
  };

  const blobToBase64 = async (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const handleDownloadDXF = async () => {
    try {
      let localSVGFileBlob = await fetch(svgUrl.replace("blob:", "")).then(
        (fetchedBlobObject) => fetchedBlobObject.blob()
      );
      const url = new URL(outputFileS3HTTPURL);
      const bucketName = url.hostname;
      const filename = url.pathname.substring(1);
      const base64Content = await blobToBase64(localSVGFileBlob);

      const params = {
        apikey: "147d31f29822ee21861aaebcf33e7a1c",
        input: "base64",
        file: base64Content,
        filename: filename,
        outputformat: "dxf",
        options: "",
      };

      const uploadResponse = await axios.post(
        "https://api.convertio.co/convert",
        params
      );
      const { id, minutes } = uploadResponse.data.data;
      toast.info(
        `Please wait for ${minutes} minutes. File is being processed in Convert.io`
      );
      setDxfId(id);
      setDxfMinutes(minutes);
      setDxfButtonLabel(`Get File in ${minutes} Minutes`);
    } catch (error) {
      console.error("Error converting SVG to DXF:", error);
      toast.error("Error Uploading the File in Convert.io");
    }
  };

  const handleGetFile = async () => {
    if (!dxfId) return;

    try {
      const downloadResponse = await axios.get(
        `https://api.convertio.co/convert/${dxfId}/dl/base64`
      );
      const base64Content = downloadResponse.data.data.content;
      const dxfBlob = new Blob(
        [Uint8Array.from(atob(base64Content), (c) => c.charCodeAt(0))],
        { type: "application/dxf" }
      );
      const dxfUrl = URL.createObjectURL(dxfBlob);
      const link = document.createElement("a");
      link.href = dxfUrl;
      const filename = outputFileS3HTTPURL.pathname.substring(1);
      link.download = `${filename}.dxf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(dxfUrl);
    } catch (error) {
      console.error("Error downloading DXF file:", error);
      toast.error("Failed to download the DXF file!");
    }
  };

  const handleDownloadSVG = () => {
    if (svgUrl) {
      try {
        const link = document.createElement("a");
        link.href = svgUrl;
        link.download = svgUrl.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file:", error);
        toast.error("Error downloading the file");
      }
    } else {
      toast.error("Output file isn't available yet");
    }
  };

  useEffect(() => {
    if (dxfMinutes !== null) {
      const interval = setInterval(() => {
        setDxfMinutes((prevMinutes) => {
          if (prevMinutes > 1) {
            return prevMinutes - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [dxfMinutes]);

  useEffect(() => {
    setDxfButtonLabel(
      dxfMinutes !== null ? `Get File in ${dxfMinutes} Minutes` : "Download DXF"
    );
  }, [dxfMinutes]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="info"
          sx={{ width: "100%" }}
        >
          Welcome to the SVG Viewer. Don't forget to leave a comment
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: "70%",
          height: "100%",
          overflow: "auto",
          padding: "2%",
        }}
      >
        {svgUrl && (
          <img
            src={svgUrl}
            alt="SVG"
            style={{ width: "100%", height: "100%" }}
          />
        )}
      </Box>
      <Box
        sx={{
          width: "30%",
          height: "100%",
          borderLeft: "1px solid #ccc",
          padding: "3%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" style={{ fontWeight: "bold" }}>
          Leave a Feedback:
        </Typography>
        <div>
          <div
            style={{
              marginTop: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: "#c76e00",
                  "&.Mui-focused": {
                    color: "#c76e00",
                  },
                }}
              >
                Zone
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={zone}
                label="Zone"
                onChange={(e) => setZone(e.target.value)}
                style={{ marginRight: 1 }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#c76e00",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#c76e00",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#c76e00",
                  },
                }}
              >
                <MenuItem value={"a"}>A</MenuItem>
                <MenuItem value={"b"}>B</MenuItem>
                <MenuItem value={"c"}>C</MenuItem>
                <MenuItem value={"d"}>D</MenuItem>
                <MenuItem value={"e"}>E</MenuItem>
                <MenuItem value={"f"}>F</MenuItem>
                <MenuItem value={"h"}>H</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  color: "#c76e00",
                  "&.Mui-focused": {
                    color: "#c76e00",
                  },
                }}
              >
                Num
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={zoneNumber}
                label="Zone"
                onChange={(e) => setZoneNumber(e.target.value)}
                style={{ marginRight: 1 }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#c76e00",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#c76e00",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#c76e00",
                  },
                }}
              >
                <MenuItem value={"1"}>1</MenuItem>
                <MenuItem value={"2"}>2</MenuItem>
                <MenuItem value={"3"}>3</MenuItem>
                <MenuItem value={"4"}>4</MenuItem>
                <MenuItem value={"5"}>5</MenuItem>
                <MenuItem value={"6"}>6</MenuItem>
                <MenuItem value={"7"}>7</MenuItem>
                <MenuItem value={"8"}>8</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Comment"
            type="text"
            multiline
            rows={4}
            fullWidth
            value={comment}
            onChange={handleCommentChange}
            style={{ marginTop: 10 }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#c76e00",
                },
                "&:hover fieldset": {
                  borderColor: "#c76e00",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#c76e00",
                },
              },
              "& .MuiInputLabel-root": {
                color: "#c76e00",
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "#c76e00",
              },
            }}
          />
        </div>

        <Stack direction="row" spacing={1} style={{ marginTop: 10 }}>
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            Experience:
          </Typography>
          <Rating
            name="simple-controlled"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
          />
        </Stack>

        <center>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCommentSave}
              style={{
                border: "1px solid #c76e00",
                borderRadius: "20px",
                backgroundColor: "white",
                color: "black",
                margin: "1% 0.3%",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Save style={{ marginRight: "8px" }} />
              <span>Save Comments</span>
            </Button>
            <Button
              variant="outlined"
              onClick={handleDownloadSVG}
              style={{
                border: "1px solid #c76e00",
                borderRadius: "20px",
                backgroundColor: "white",
                color: "black",
                margin: "1% 0.3%",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <CloudDownload style={{ marginRight: "8px" }} />
              <span>Download SVG</span>
            </Button>
          </div>
          <Button
            variant="outlined"
            onClick={
              dxfButtonLabel.startsWith("Get File in")
                ? handleGetFile
                : handleDownloadDXF
            }
            style={{
              border: "1px solid #c76e00",
              borderRadius: "20px",
              backgroundColor: "white",
              color: "black",
              margin: "1% 0.3%",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <CloudDownload style={{ marginRight: "8px" }} />
            <span>{dxfButtonLabel}</span>
          </Button>
        </center>
        <Typography variant="h6" style={{ fontWeight: "bold", marginTop: 10 }}>
          Comments
        </Typography>
        {loading ? (
          <div>Loading comments...</div>
        ) : (
          <ul>
            {comments.map((comment, index) => (
              <CommentCard key={index} comment={comment} />
            ))}
          </ul>
        )}
      </Box>
    </Box>
  );
};

export default Viewer;
