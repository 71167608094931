import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AWS from "aws-sdk";
import { fetchAllComments } from "../../utils/helper";
import CommentCard from "../SVG/CommentCard";

function CommentsDialog(props) {
  const { open, setOpen, partID } = props;
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  const s3 = new AWS.S3();

  React.useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const fetchedComments = await fetchAllComments(s3, partID);
        const sortedComments = fetchedComments.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        setComments(sortedComments);
      } catch (error) {
        console.error("Error fetching comments:", error);
      } finally {
        setLoading(false);
      }
    };

    if (partID) {
      getData(); // Fetch comments when partId is available
    }
  }, [partID]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          id="scroll-dialog-title"
          style={{ fontWeight: "bold", color: "#c76e00" }}
        >
          Feedback
        </DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {loading ? (
              <p>Loading comments...</p>
            ) : (
              <div>
                {comments.map((comment, index) => (
                  <CommentCard key={index} comment={comment} />
                ))}
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: "#c76e00" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CommentsDialog;
