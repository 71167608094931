import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoutes() {
  const userID = localStorage.getItem("userId");
  const userEmail = localStorage.getItem("userEmail");
  const isUserLogedIn = userID && userEmail;

  return isUserLogedIn ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedRoutes;
