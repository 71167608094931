import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react'
import { getUserInfo } from './helper';


const PostHogPageviewTracker = () => {
    const location = useLocation();
    const posthog = usePostHog()
    useEffect(() => {
        const user = getUserInfo();
        if (posthog) {
            posthog.capture('$pageview')
            location.pathname === "/userDashboard" ? posthog.capture("home_page_visited", user) : posthog.capture("drawing_page_visited", user)
        }
    }, [location, posthog])

    return null;
};

export default PostHogPageviewTracker